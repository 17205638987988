
  import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
  import { HasDesktopPart } from "@/mixins/properties/has-desktop-part";
  import GeneralNavLink from "@/components/general/nav-link.vue";
  import { HasPropertyOptions } from "@/mixins/properties/has-options";

  @Component({
    components: {
      GeneralNav: () => import("@/components/general/nav.vue"),
      GeneralNavLink: () => import("@/components/general/nav-link.vue"),
      PropertyPartDesktopNumber: () => import("@/views/auth/properties/parts/desktop/number.vue"),
      PropertyPartDesktopTextinput: () => import("@/views/auth/properties/parts/desktop/textinput.vue"),
      PropertyPartDesktopTextarea: () => import("@/views/auth/properties/parts/desktop/textarea.vue"),
      PropertyPartDesktopYesNo: () => import("@/views/auth/properties/parts/desktop/yes-no.vue"),
      PropertyPartDesktopSelect: () => import("@/views/auth/properties/parts/desktop/select.vue"),
    },
  })
  export default class PagePropertyPartDesktopRooms extends Mixins(HasDesktopPart, HasPropertyOptions) {
    @Prop({ default: () => [] }) value!: PropertyRoom[];

    editingRoomIndex: number = -1;

    payload: PropertyRoom = {
      type: "",
      title: "",
      area: null,
      floor: null,
      is_livable_area: true,
      renovation_needed: false,
      renovation_year: undefined,
      note: undefined,
    };

    mounted() {
      if (this.localValue.length > 0) {
        this.editingRoomIndex = 0;
      }
    }

    /**
     * @description Change the index of the room being edited
     */
    changeIndex(index: number) {
      this.editingRoomIndex = index;
    }

    /**
     * @description Add a new room to the property
     */
    addRoom() {
      const newEmptyRoom = {
        type: null,
        title: null,
        area: null,
        floor: null,
        is_livable_area: true,
        renovation_needed: false,
        renovation_year: null,
        note: null,
      };

      const newRooms = [...this.localValue, newEmptyRoom];

      this.$emit("input", newRooms);

      this.$nextTick(() => {
        this.editingRoomIndex = newRooms.length - 1;
      });
    }

    /**
     * @description Remove a room from the property
     */
    removeRoom() {
      this.localValue!.splice(this.editingRoomIndex, 1);

      this.$emit("input", this.localValue);

      this.$nextTick(() => {
        this.editingRoomIndex = this.localValue.length - 1;
      });
    }

    /**
     * @description Get the title for the room navigation
     */
    navTitle(room: PropertyRoom) {
      if (!room) {
        return this.$t("property.room.new");
      }

      if (room.title) {
        return room.title;
      }

      if (room.type) {
        return this.$t(`property.room.types.${room.type}`);
      }

      return this.$t("property.room.new");
    }

    /**
     * @description Do some extra stuff when the room type is changed
     */
    handleRoomTypeChanged(event: string) {
      if (!this.payload) {
        return;
      }
      const type = this.propertyRoomTypeOptions.find((type: { value: string | number; label: string }) => type.value === event);
      if (type) {
        this.payload.title = type.label;
      }
      if (event !== "KITCHEN") {
        this.payload.kitchen_type = null;
      }
      if (event !== "BATHROOM") {
        this.payload.bathroom_type = null;
      }
    }

    /**
     * @description Watch for changes in the editing room index and update the payload
     */
    @Watch("editingRoomIndex", { immediate: true })
    onEditingRoomIndexChange() {
      if (this.editingRoomIndex >= 0 && this.localValue && this.localValue.length > 0) {
        this.payload = this.localValue[this.editingRoomIndex];
      }
    }
  }
